import React, { useState } from "react";
import AppBar from '@mui/material/AppBar';
import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NavLink } from "react-router-dom";
import routes from "../../router/PageRoutes";
import { SvgIcon } from "@mui/material";
import Logo from "../../asset/Logo.png";
import { useNavigate } from "react-router-dom";
import { menuName } from "../../recoil/atom"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRecoilState } from "recoil";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { ReactComponent as LogoutIcon } from "../../asset/logoutLight.svg";
import { ReactComponent as SupportIcon } from "../../asset/supportDark.svg";
import {
  Avatar,
} from "@mui/material";
import configParam from "../../Config";
const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"

}));

function ResponsiveDrawer(props) {
  // const { window } = props;
  const matches = useMediaQuery('(max-width:600px)');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menu, setMenu] = useRecoilState(menuName);
  const userDet = localStorage.getItem("user");
  const userValue = JSON.parse(userDet);
  let roleID = JSON.parse(userDet);

  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [, setAnchorEl] = useState(null);
  const Logout = () => {
    setAnchorEl(null);
    localStorage.setItem("userDetails", "");
    localStorage.setItem("userId", "");
    // const url = 'https://pp.websso.saint-gobain.com/cas/logout';
    const url = configParam.REDIRECTION_URL();
    window.location.href = url;
  };
  function getCurrPage() {
    var name;
    routes.mainRoutes.map((prop) => {
      if (window.location.href.endsWith(prop.path)) {
        name = prop.name;
      }
      return null;
    });
    return name;
  }
  const goToSupport = () => {
    setAnchorEl(null);
    navigate("/support");
  };
  const changeMenu = (name) => {
    setMenu(name)
  }
  function getListItemSet(text, index) {
    if ((text.name === "Data Management" || text.name === "User Management") && (roleID["role_id"] === 3)) {
      return null
    }
    else {
      if (roleID["role_id"] !== 3) {
        return text.icon ? (
          <NavLink key={index} style={{ textDecoration: "none" }} to={text.path}>

            <ListItem
              key={text}
              disablePadding
              sx={{ display: "block" }}
              style={{ backgroundColor: menu === text.name ? "#fdc500" : "" }}
              onClick={() => changeMenu(text.name)}
            >
              <ListItemButton
                sx={{
                  minHeight: 58,
                  justifyContent: mobileOpen ? "initial" : "center",
                  px: 2.5,
                }}

              >
                <SvgIcon style={{ color: "black" }}>
                  <text.icon stock={"black"} />
                </SvgIcon>
                <ListItemText primary={text.name} sx={{ opacity: mobileOpen ? 1 : 1 }} style={{ marginLeft: 20, color: "black" }} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ) : null;
      } else {
        if (!text.roleWiseDispaly) {
          return text.icon ? (
            <NavLink key={index} style={{ textDecoration: "none" }} to={text.path}>

              <ListItem
                key={text}
                disablePadding
                sx={{ display: "block" }}
                style={{ backgroundColor: menu === text.name ? "#fdc500" : "" }}
                onClick={() => changeMenu(text.name)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 58,
                    justifyContent: mobileOpen ? "initial" : "center",
                    px: 2.5,
                  }}

                >
                  <SvgIcon style={{ color: "black" }}>
                    <text.icon stock={"black"} />
                  </SvgIcon>
                  <ListItemText primary={text.name} sx={{ opacity: mobileOpen ? 1 : 1 }} style={{ marginLeft: 20, color: "black" }} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ) : null;
        }
      }

    }
  }


  const drawer = (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
      <div>
        <Toolbar />
        <Divider />
        <List>
          {routes.mainRoutes.map((text, index) => getListItemSet(text, index))}
        </List>

        <Divider />
      </div>
      <div style={{ display: "flex", width: '100%', marginBottom: 20, paddingTop: '20px', borderTop: '1px solid #ccc' }}>
        <div
          style={{
            cursor: "pointer",
            userSelect: "none",
            marginLeft: 17,
          }}
          onClick={goToSupport}
          id="help_and_support"
        >
          <SupportIcon />
        </div>
        <div style={{ marginLeft: 22, fontSize: "16px" }}>
          Help & Support
        </div>
      </div>
    </div>
  );

  const container = undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: "#fff", border: "none", boxShadow: "none" }}
        position="fixed"
        backgroundColor="transparant"

        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >

        <Toolbar style={{ justifyContent: "flex-end", backgroundColor: "white" }}>
          <IconButton
            color="#fdc500"
            aria-label="open drawer"
            edge={matches ? "start" : null}
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h2' sx={{ fontSize: { xs: 14, md: 24 }, color: '#000', fontWeight: 600, display: 'flex' }}><span sx={{ display: { xs: 'none', md: 'block' } }}>Welcome</span> <div id="user_name" style={{ marginLeft: '10px', textTransform: 'capitalize' }}> {userValue.first_name + " " + userValue.last_name}!</div></Typography>
          <div style={{ display: 'flex', marginLeft: "auto" }}>
            <Avatar sx={{ display: { xs: 'none', md: 'flex', backgroundColor: '#fdc500', marginRight: 4, textTransform: 'uppercase', color: '#000' } }}
            >
              {userValue.first_name.charAt(0) + "" + userValue.last_name.charAt(0)}
            </Avatar>
            <Tooltip title="Logout">
              <Button
                variant="text"
                size="small"
                onClick={Logout}
              >
                <LogoutIcon style={{ fill: '#000' }} />
              </Button>
            </Tooltip>
          </div>

        </Toolbar>
        {/* {console.log(curPage)}
        {
          (curPage === "Trial Visits" || curPage === "New Visit") &&
          <div style={{ marginLeft: "20px" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" onClick={() => { navigate("/home"); setMenu("Visit") }} style={{ cursor: "pointer", color: "#737373", fontWeight: "400" }}>Home</Link>
              <Typography color="text.primary">{curPage}</Typography>
            </Breadcrumbs>
          </div>
        } */}
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}

        >
          {drawer}

        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open

        >
          <DrawerHeader style={{ marginTop: 10, marginBottom: -50, }}>
            <img src={Logo} alt="" style={{ width: 100, height: 64, float: "left" }} />
          </DrawerHeader>
          {drawer}

        </Drawer>
      </Box>

    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  // window: PropTypes.func,
};

export default ResponsiveDrawer;
