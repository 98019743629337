import { selector } from "recoil";
import { selectedVisitObj, visitType } from "../../src/recoil/atom";
const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
export const getConvertedJSON = selector({
  key: "getConvertedJSON",
  get: ({ get }) => {
    let VisitDetails = {};
    let resData = deepCopy(get(selectedVisitObj));
    console.log("resDataresData",resData.retention)
    let visitTypeValue = deepCopy(get(visitType));
    if (Object.keys(resData).length > 0 && resData.trialid) {
   
      VisitDetails = {
        ids: {
          trial_details_id: resData.trialdetailid,
          client_details_id: resData.clientdetailsid,
          concrete_mix_details_id: resData.concretemixid,
          supporting_info_id: resData.supportinginfoid,
          admixture_composition_id: resData.admixtureid,
          trial_results_id: resData.trialresultid,
          comments_id: resData.commentid,
          trialId: resData.trialid,
        },
        TrialDetails: {
          trial_number: resData.trialno,
          trial_date: resData.trial_date,
          tech_person_1: resData.tech_person_id_1,
          tech_person_2: resData.tech_person_id_2,
          laboratory_type: resData.laboratory_type_id,
        },
        ClientDetails: {
          air_content: resData.air_content,
          concrete_grade: resData.concrete_grade_id,
          concrete_type: resData.concrete_type_id,
          customer_name: { "id": resData.customer_name, "name": resData.customername,ischecked: false },
          // customer_name:resData.customer_name,
          early_strength_time: resData.early_strength_time_id,
          enquiry_number: resData.enquiry_number,
          project_details: resData.project_details,
          project_location: resData.project_location,
          project_type: { "id": resData.project_type_id, "type": resData.projecttype, ischecked: false },
          retention: resData.retention,
          target_strength_28_days:
            resData.target_strength_28_days,
          workability_flow_slump:
            resData.workability_flow_slump,
          early_strength_mpa: resData.early_strength_mpa,
        },
        ConcreteDetails: {
          cement_brand: resData.cement_brand,
          cement_content: resData.cement_content,
          flyash: resData.flyash,
          free_water: resData.free_water,
          ggbfs_content: resData.ggbfs_content,
          microsilica_content:
            resData.microsilica_content,
          aggregates: resData.aggregates,
        },
        supInfo: {
          alkali_content: resData.alkali_content,
          ambient_humidity: resData.ambient_humidity,
          ambient_temperature: resData.ambient_temperature,
          clear_test10: resData.clear_test10,
          clear_test50: resData.clear_test50,
          coarse_aggregate_150micpass10:
            resData.coarse_aggregate_150micpass10,
          coarse_aggregate_150micpass20:
            resData.coarse_aggregate_150micpass20,
          sand_category: resData.sand_category_id,
          sand_passing150: resData.sand_passing150,
          sand_passing600: resData.sand_passing600,
          w_cm: resData.w_cm,
        },
        Admixture: {
          a15_p24: resData.compositions.a15_p24,
          ac1: resData.compositions.ac1,
          ac2_ac3: resData.compositions.ac2_ac3,
          ac5_ac6: resData.compositions.ac5_ac6,
          ac6: resData.compositions.ac6,
          ar1: resData.compositions.ar1,
          ar2: resData.compositions.ar2,
          ar3: resData.compositions.ar3,
          ar4: resData.compositions.ar4,
          ar5: resData.compositions.ar5,
          ar6: resData.compositions.ar6,
          ar7: resData.compositions.ar7,
          b29_p23: resData.compositions.b29_p23,
          ca: resData.compositions.ca,
          cf: resData.compositions.cf,
          cr_1: resData.compositions.cr_1,
          df1: resData.compositions.df1,
          df2: resData.compositions.df2,
          df3: resData.compositions.df3,
          g1: resData.compositions.g1,
          gly: resData.compositions.gly,
          gn1: resData.compositions.gn1,
          gn2: resData.compositions.gn2,
          l1_ligno_50:
            resData.compositions.l1_ligno_50,
          ligno: resData.compositions.ligno,
          n43: resData.compositions.n43,
          other: resData.compositions.other,
          p11: resData.compositions.p11,
          p15: resData.compositions.p15,
          p16: resData.compositions.p16,
          p18_hr150: resData.compositions.p18_hr150,
          p20: resData.compositions.p20,
          p25_a19: resData.compositions.p25_a19,
          p26: resData.compositions.p26,
          p27_r650: resData.compositions.p27_r650,
          pr1: resData.compositions.pr1,
          snf_n5: resData.compositions.snf_n5,
          srno_Pname: resData.compositions.srno_Pname,
          vma1: resData.compositions.vma1,
          vma2: resData.compositions.vma2,
        },
        TrialResult: {
          air_content: resData.traircontent,
          dosage: resData.dosage,
          early_strength: resData.early_strength,
          retention: resData.trretention,
          strength_at_7_days: resData.strength_at_7_days,
          strength_at_28_days: resData.strength_at_28_days,
          workability: resData.workability,
        },
        comments: {
          cost_factor_comparison: resData.cost_factor_comparison,
          ref_previous_trial_data: resData.ref_previous_trial_data,
          remarks: resData.remarks,
          status: resData.status,
          rejection_notes: resData.rejection_notes,
        },
        status: resData.submissionstatus,
      };
    } else {

      VisitDetails = {
        TrialDetails: {
          trial_number: localStorage.getItem("trialNo"),
          trial_date: new Date(),
          tech_person_1: "",
          tech_person_2: "",
          laboratory_type: "",
        },
      }
    }
    return VisitDetails;
  },
});
