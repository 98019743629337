import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select, Typography } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
export default function SelectItem(props) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl style={{ width: "100%" }}>
        <Typography style={{ fontSize: "12px", lineHeight: 1.75, fontWeight: 600, marginBottom: "5px" }}>{props.label}</Typography>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          name={props.name}
          value={props.value}
          size="small"
          onChange={props.onChange}
          error={props.error ? props.error : false}
        >
          {props.data.length ? (
            props.data.map((d) => (
              <MenuItem
                key={props.datakey && props.dataValue ? d[props.datakey] : d}
                id={props.datakey && props.dataValue ? d[props.datakey] : d}
                value={props.datakey && props.dataValue ? d[props.datakey] : d}
              >
                {props.datakey && props.dataValue ? d[props.dataValue] : d}
              </MenuItem>
            ))
          ) : (
            <MenuItem>None</MenuItem>
          )}
        </Select>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
}
