import React, { useEffect, useState } from "react";
import {
  Button,
  Grid
} from "@mui/material";
import gqlQueries from "../Queries/Queries";
import configParam from "../Config";
import { ReactComponent as Delete } from "../asset/delete.svg";
import { ReactComponent as Edit } from "../asset/Edit.svg";
import UserForm from "./UserForm";
import SuccessPopup from "../input/SuccessPopup";
import DeletePopup from "../newVisit/DeletePopup";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Tooltip from '@mui/material/Tooltip';
import { user } from "../recoil/atom";
import { useRecoilValue } from "recoil";
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios";
const RightContainer = () => {
  const matches = useMediaQuery('(max-width:600px)');
  const [users, setusers] = useState([]);
  const currUser = useRecoilValue(user);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [tabledata, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = useState("");
  const [status, setStatus] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const ApiURL = configParam.API_URL;
  const userDet = localStorage.getItem("user");
  const userValue = JSON.parse(userDet);
  let roleID = JSON.parse(userDet);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onHandleEdit = (index) => {
    setType("Update User");
    setCurrentUsers(index.row);
    setOpen(true);
  };
  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      width: 100,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "sgid",
      headerName: "SG Id",
      width: 200,
      editable: false,
    },

    {
      field: "first_name",
      headerName: "First Name",
      width: 220,
      editable: false,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 220,
      editable: false,
    },
    {
      field: "name",
      headerName: "Zone",
      width: 150,
      editable: false,
    },

    {
      field: "role",
      headerName: "Role",
      width: 150,
      editable: false,
    },

    {
      field: "Action",
      headerName: "Actions",
      sortable: false,
      width: 150,
      disableClickEventBubbling: false,
      renderCell: (index) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Tooltip title="Edit">
              <Edit
                id={index}
                style={{ marginRight: "15px", cursor: "pointer" }}
                onClick={() => {
                  onHandleEdit(index);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon onClick={() => handleDeleteRow(index)} style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        );
      },

      hide: roleID["role_id"] !== 3 ? false : true
    }
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const getUserDetails = () => {
    let url = ApiURL + 'get_users_list';
    axios.get(url).then((response) => {
      setTableData(response.data);
    }).catch((error) => {
      setTableData([]);
      console.log("get_current_users", error);
    });
  };


  const rowDelete = (index) => {
    const userDetails = {
      userID: index["row"].id
    };
    let url = ApiURL + 'delete_users';
    axios.post(url, { userDetails }).then((response) => {
      if (response.data.affected > 0) {
        setShow(true);
        setDeleteModule(false);
        getUserDetails();
        handleClose();
        setType("Delete User");
      }
    }).catch((error) => {
      // setTableData([]);
      console.log("get_current_users", error);
    });
    // configParam
    //   .RUN_GQL_API(gqlQueries.DEL_USER, { id: index["row"].id })
    //   .then((data) => {
    //     if (data.update_chryso_users
    //       .affected_rows > 0) {
    //       setShow(true);
    //       getUserDetails();
    //       handleClose();
    //       setType("Delete User");
    //     }
    //   });
  };

  const handleDeleteRow = (index) => {
    setDeleteModule(true);
    setRowIndex(index)
  };
  const handleClose = () => {
    setOpen(false);
    getUserDetails();
    setDeleteModule(false);

  };
  const addNew = () => {
    setType("Add User");
    setCurrentUsers([]);
    setOpen(true);
  };

  const setStatusValue = (value) => {
    setStatus(value);
  };

  const onHandleClosed = () => {
    setShow(false);
    setDeleteModule(false);
  };

  const onHandleAlert = () => {
    setShow(true);
  };

  return (

    <div >
      <SuccessPopup open={show} onClose={onHandleClosed} msg={type === 'Add User' ? "User Added Successfully" : type === 'Update User' ? "User Updated Successfully" : "User Deleted Successfully"} />
      <DeletePopup open={deleteModule} onClose={onHandleClosed} handleDelete={() => rowDelete(rowIndex)} />
      <UserForm
        open={open}
        handleClose={handleClose}
        type={type}
        data={currentUsers}
        setStatus={setStatusValue}
        onHandleAlert={onHandleAlert}
      />

      <Grid container direction='column'>
        <Grid item style={{}}>
          {roleID["role_id"] !== 3 ? (<Button
            variant="contained"
            style={{
              marginBottom: "10px",
              width: matches ? "100%" : null,
              float: "right",
              // marginLeft: "5%",
              backgroundColor: "#FFC400",
              color: "#1A1B1F",
            }}
            onClick={addNew}
          >
            Add New User
          </Button>) : null}

        </Grid>
        <Grid item>
          <Box sx={{ height: 550, width: "100%" }}>
            <DataGrid
              rows={tabledata && tabledata.length > 0 ? tabledata : []}
              columns={columns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 }
                }
              }}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default RightContainer;
