import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FixedSizeList as List } from "react-window";
import configParam from "../../Config";
import gqlQueries from "../../Queries/Queries";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useRecoilState, useRecoilValue } from "recoil";
import RangeFilter from "./RangeFilter";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { List } from "react-virtualized";
import {
  searchValue,
  RangeFilterData,
  isReadOnly,
  checkboxList,
  projectLocationList,
  rangeFilterValue,
  toRetentionFilter,
  frmRetentionFilter,
  checkBoxCount,
  LocationCount,
  AdvanceSearchLoading,
  graphSeriesData,
  projectType,
  typeofConcrete,
  enquiryNumberList,
  customerList,
  projectDetailList,
  congreteGrade,
  cementBrand,
  status,
  zoneFilter,
  searchList,
  advancefilterTotal,
  isFilter,
  techPerson,
  totlaTableCount,
  filterFromDate,
  filterToDate,
  AdvanceFilterCount,
  filterDataArray,
  filterPagination,
  filterExcelData,
  excelTotlaTableCount,
  filterTableLoader,
  resetTotlaTableCount,
  filterInputSearchList
} from "../../recoil/atom";
import HourMinInput from "../../input/HourMinInput";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import moment from "moment";
import TrialDate from "../../input/TrialDate";

import axios from "axios";
// const filterValue = {};
let searchCategory = {
  "Client Details & Parameters": [
    "Type of Project",
    "Type of Concrete",
    "Customer Name",
    "Project Details",
    "Project Location",
    "Grade of concrete",
    "Retention (Hr : Min)",
    "Workability  Flow / Slump (mm)",
    "Air Content %",
    "Early Strength (Mpa)",
    "Target Strength 28 days (Mpa)",
  ],
  "Concrete Mix Design Details": [
    "Cement Brand/Grade/Source",
    "Cement Content ( Kg)",
    "Flyash Content (Kg)",
    "GGBFS Content (Kg)",
    "Microsilica  Content (Kg)",
  ],
  "Supporting Information": [
    "W/CM (%)",
    "Alkali content in cement(%)",
    "Sand Passing % in 600 µ",
    "Sand Passing % in 150 µ",
    "Clear Test 10",
    "Clear Test 50",
    "Coarse Aggregate 150 micron passing 20 mm",
    "Coarse Aggregate 150 micron passing 10 mm",
    "Ambient Temperature (°C)",
    "Ambient Humidity (%)",
    "Success / Fail",
  ],
};

const TypeofConcreteCheckbox = () => {
  const [projectTypeList, setProjectTypeList] = useRecoilState(typeofConcrete);
  const [searchTerm, setSearchTerm] = useState("");

  const onChangeChangeProjectType = (e) => {
    const updatedProjectTypeList = projectTypeList.map((item) => {
      if (item.id === e.target.value) {
        return {
          ...item,
          ischecked: !item.ischecked,
        };
      } else {
        return item;
      }
    });
    setProjectTypeList(updatedProjectTypeList);
  };
  const filteredProjectTypeList = projectTypeList.filter((data) =>
    data.type.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const Row = ({ index, style }) => {
    const item = filteredProjectTypeList[index];
    return (
      <div style={style}>
        <FormControlLabel
          control={
            <Checkbox
              name={"type_of_concrete"}
              value={item.id}
              onClick={(e) => onChangeChangeProjectType(e)}
              id={index}
              checked={item.ischecked}
            />
          }
          label={item.type}
        />
      </div>
    );
  };

  if (projectTypeList.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          style={{
            height: "40px",
            width: "100%",
            outline: "none",
            border: "1px solid #ccc",
            borderRadius: "5px",
            paddingLeft: "10px",
          }}
          type="text"
          placeholder="Search Customer Name.."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Grid
          item
          xs={12}
          style={{ height: "400px", overflow: "auto", marginTop: 15 }}
        >
          <List
            height={400}
            itemCount={filteredProjectTypeList.length}
            itemSize={35}
            width={"100%"}
          >
            {Row}
          </List>
        </Grid>
      </div>
    );
  } else {
    return <p>No Concrete Type available.</p>;
  }
};

const CustomerListCheckBox = (props) => {
  const [projectTypeList, setProjectTypeList] = useRecoilState(customerList);
  const [searchTerm, setSearchTerm] = useState("");

  const onChangeChangeProjectType = (e) => {
    const updatedProjectTypeList = projectTypeList.map((item) => {
      if (item.id === e.target.value) {
        return {
          ...item,
          ischecked: !item.ischecked,
        };
      } else {
        return item;
      }
    });
    setProjectTypeList(updatedProjectTypeList);
  };
  const filteredProjectTypeList = projectTypeList.filter((data) =>
    data.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const Row = ({ index, style }) => {
    const item = filteredProjectTypeList[index];
    return (
      <div style={style}>
        <FormControlLabel
          control={
            <Checkbox
              name={"customer_list"}
              value={item.id}
              onClick={(e) => onChangeChangeProjectType(e)}
              id={index}
              checked={item.ischecked}
            />
          }
          label={item.name}
        />
      </div>
    );
  };
  if (projectTypeList.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          style={{
            height: "40px",
            width: "100%",
            outline: "none",
            border: "1px solid #ccc",
            borderRadius: "5px",
            paddingLeft: "10px",
          }}
          type="text"
          placeholder="Search Customer Name.."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Grid
          item
          xs={12}
          style={{ height: "400px", overflow: "auto", marginTop: 15 }}
        >
          <List
            height={400}
            itemCount={filteredProjectTypeList.length}
            itemSize={30}
            width={"100%"}
          >
            {Row}
          </List>
        </Grid>
      </div>
    );
  } else {
    return <p>No Customer name available.</p>;
  }
};
const ProjectTypeCheckBox = (props) => {
  const [projectTypeList, setProjectTypeList] = useRecoilState(projectType);
  const [searchTerm, setSearchTerm] = useState("");
  const onChangeChangeProjectType = (e) => {
    const updatedProjectTypeList = projectTypeList.map((item) => {
      if (item.id === e.target.value) {
        return {
          ...item,
          ischecked: !item.ischecked,
        };
      } else {
        return item;
      }
    });
    setProjectTypeList(updatedProjectTypeList);
  };

  const filteredProjectTypeList = projectTypeList.filter((data) =>
    data.type.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const Row = ({ index, style }) => {
    const item = filteredProjectTypeList[index];
    return (
      <div style={style}>
        <FormControlLabel
          control={
            <Checkbox
              name={"chryso_project_type"}
              value={item.id}
              onClick={(e) => onChangeChangeProjectType(e)}
              id={index}
              checked={item.ischecked}
            />
          }
          label={item.type}
        />
      </div>
    );
  };

  if (projectTypeList.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          style={{
            height: "40px",
            width: "100%",
            outline: "none",
            border: "1px solid #ccc",
            borderRadius: "5px",
            paddingLeft: "10px",
          }}
          type="text"
          placeholder="Search project type"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Grid
          item
          xs={12}
          style={{ height: "400px", overflow: "auto", marginTop: 15 }}
        >
          <List
            height={400}
            itemCount={filteredProjectTypeList.length}
            itemSize={30}
            width={"100%"}
          >
            {Row}
          </List>
        </Grid>
      </div>
    );
  } else {
    return <p>No Project Type available.</p>;
  }
};
const ProjectDetailsCheckBox = (props) => {
  const [projectTypeList, setProjectTypeList] =
    useRecoilState(projectDetailList);
  const [searchTerm, setSearchTerm] = useState("");
  const onChangeChangeProjectType = (e) => {
    const updatedProjectTypeList = projectTypeList.map((item) => {
      if (item.project_details === e.target.value) {
        return {
          ...item,
          ischecked: !item.ischecked,
        };
      } else {
        return item;
      }
    });
    setProjectTypeList(updatedProjectTypeList);
  };
  const filteredProjectTypeList = projectTypeList.filter((data) =>
    data.project_details.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const Row = ({ index, style }) => {
    const item = filteredProjectTypeList[index];
    return (
      <div style={style}>
        <FormControlLabel
          control={
            <Checkbox
              name={"project_details"}
              value={item.project_details}
              onClick={(e) => onChangeChangeProjectType(e)}
              id={index}
              checked={item.ischecked}
            />
          }
          label={item.project_details}
        />
      </div>
    );
  };
  if (projectTypeList.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          style={{
            height: "40px",
            width: "100%",
            outline: "none",
            border: "1px solid #ccc",
            borderRadius: "5px",
            paddingLeft: "10px",
          }}
          type="text"
          placeholder="Search Project Details.."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Grid
          item
          xs={12}
          style={{ height: "400px", overflow: "auto", marginTop: 15 }}
        >
          <List
            height={400}
            itemCount={filteredProjectTypeList.length}
            itemSize={40}
            width={"100%"}
          >
            {Row}
          </List>
        </Grid>
      </div>
    );
  } else {
    return <p>No Project Details available.</p>;
  }
};

const EnquiryNumCheckBox = (props) => {
  const [projectTypeList, setProjectTypeList] = useRecoilState(enquiryNumberList);
  
  const [searchTerm, setSearchTerm] = useState("");

  const onChangeChangeProjectType = (e) => {
    const updatedProjectTypeList = projectTypeList.map((item) => {
      if (item.enquiry_number === e.target.value) {
        return {
          ...item,
          ischecked: !item.ischecked,
        };
      } else {
        return item;
      }
    });
    setProjectTypeList(updatedProjectTypeList);
  };
  const filteredProjectTypeList = projectTypeList.filter((data) =>
    data.enquiry_number.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const Row = ({ index, style }) => {
    const item = filteredProjectTypeList[index];
    return (
      <div style={style}>
        <FormControlLabel
          control={
            <Checkbox
              name={"enquiry_number"}
              value={item.enquiry_number}
              onClick={(e) => onChangeChangeProjectType(e)}
              id={index}
              checked={item.ischecked}
            />
          }
          label={item.enquiry_number}
        />
      </div>
    );
  };

  if (projectTypeList.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          style={{
            height: "40px",
            width: "100%",
            outline: "none",
            border: "1px solid #ccc",
            borderRadius: "5px",
            paddingLeft: "10px",
          }}
          type="text"
          placeholder="Search Enquiry Number.."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Grid
          item
          xs={12}
          style={{ height: "400px", overflow: "auto", marginTop: 15 }}
        >
          <List
            height={400}
            itemCount={filteredProjectTypeList.length}
            itemSize={30}
            width={"100%"}
          >
            {Row}
          </List>
        </Grid>
      </div>
    );
  } else {
    return <p>No EnquiryNumber available.</p>;
  }
};

const CongreteGradeCheckBox = (props) => {
  const [projectTypeList, setProjectTypeList] = useRecoilState(congreteGrade);
  const [searchTerm, setSearchTerm] = useState("");

  const onChangeChangeProjectType = (e) => {
    const updatedProjectTypeList = projectTypeList.map((item) => {
      if (item.id === e.target.value) {
        return {
          ...item,
          ischecked: !item.ischecked,
        };
      } else {
        return item;
      }
    });
    setProjectTypeList(updatedProjectTypeList);
  };
  const filteredProjectTypeList = projectTypeList.filter((data) =>
    data.grade.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const Row = ({ index, style }) => {
    const item = filteredProjectTypeList[index];
    return (
      <div style={style}>
        <FormControlLabel
          control={
            <Checkbox
              name={"congrete_Grade"}
              value={item.id}
              onClick={(e) => onChangeChangeProjectType(e)}
              id={index}
              checked={item.ischecked}
            />
          }
          label={item.grade}
        />
      </div>
    );
  };

  if (projectTypeList.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          style={{
            height: "40px",
            width: "100%",
            outline: "none",
            border: "1px solid #ccc",
            borderRadius: "5px",
            paddingLeft: "10px",
          }}
          type="text"
          placeholder="Search Customer Name.."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Grid
          item
          xs={12}
          style={{ height: "400px", overflow: "auto", marginTop: 15 }}
        >
          <List
            height={400}
            itemCount={filteredProjectTypeList.length}
            itemSize={30}
            width={"100%"}
          >
            {Row}
          </List>
        </Grid>
      </div>
    );
  } else {
    return <p>No Customer name available.</p>;
  }
};
const TechPersionCheckBox = (props) => {
  const [projectTypeList, setProjectTypeList] = useRecoilState(techPerson);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => { }, [projectTypeList]);

  const onChangeChangeProjectType = (e) => {
    const updatedProjectTypeList = projectTypeList.map((item) => {
      if (item.id === e.target.value) {
        return {
          ...item,
          ischecked: !item.ischecked,
        };
      } else {
        return item;
      }
    });
    setProjectTypeList(updatedProjectTypeList);
  };

  const filteredProjectTypeList = projectTypeList.filter((data) =>
    data.first_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const Row = ({ index, style }) => {
    const item = filteredProjectTypeList[index];
    return (
      <div style={style}>
        <FormControlLabel
          control={
            <Checkbox
              name={"tech_persion_name"}
              value={item.id}
              onClick={(e) => onChangeChangeProjectType(e)}
              id={index}
              checked={item.ischecked}
            />
          }
          label={item.first_name + " " + item.last_name}
        />
      </div>
    );
  };
  if (projectTypeList.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          style={{
            height: "40px",
            width: "100%",
            outline: "none",
            border: "1px solid #ccc",
            borderRadius: "5px",
            paddingLeft: "10px",
          }}
          type="text"
          placeholder="Search Name.."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Grid
          item
          xs={12}
          style={{ height: "400px", overflow: "auto", marginTop: 15 }}
        >
          <List
            height={400}
            itemCount={filteredProjectTypeList.length}
            itemSize={30}
            width={"100%"}
          >
            {Row}
          </List>
        </Grid>
      </div>
    );
  } else {
    return <p>No Technical Person name available.</p>;
  }
};

const CementBrandCheckBox = (props) => {
  const [projectTypeList, setProjectTypeList] = useRecoilState(cementBrand);
  const [searchTerm, setSearchTerm] = useState("");

  const onChangeChangeProjectType = (e) => {
    const updatedProjectTypeList = projectTypeList.map((item) => {
      if (item.id === e.target.value) {
        return {
          ...item,
          ischecked: !item.ischecked,
        };
      } else {
        return item;
      }
    });
    setProjectTypeList(updatedProjectTypeList);
  };

  const filteredProjectTypeList = projectTypeList.filter((data) =>
    data.type.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const Row = ({ index, style }) => {
    const item = filteredProjectTypeList[index];
    return (
      <div style={style}>
        <FormControlLabel
          control={
            <Checkbox
              name={"cement_brand"}
              value={item.id}
              onClick={(e) => onChangeChangeProjectType(e)}
              id={index}
              checked={item.ischecked}
            />
          }
          label={item.type}
        />
      </div>
    );
  };
  if (projectTypeList.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          style={{
            height: "40px",
            width: "100%",
            outline: "none",
            border: "1px solid #ccc",
            borderRadius: "5px",
            paddingLeft: "10px",
          }}
          type="text"
          placeholder="Search Cement Brand.."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Grid
          item
          xs={12}
          style={{ height: "400px", overflow: "auto", marginTop: 15 }}
        >
          <List
            height={400}
            itemCount={filteredProjectTypeList.length}
            itemSize={35}
            width={"100%"}
          >
            {Row}
          </List>
        </Grid>
      </div>
    );
  } else {
    return <p>No Cement Brand available.</p>;
  }
};
const StatusCheckBox = (props) => {
  const [projectTypeList, setProjectTypeList] = useRecoilState(status);
  var checkBox = [];
  if (projectTypeList.length > 0) {
    const onChangeChangeProjectType = (e) => {
      let value = JSON.parse(JSON.stringify(projectTypeList));
      value[e.target.id]["ischecked"] = value[e.target.id]["ischecked"]
        ? false
        : true;
      setProjectTypeList(value);
    };

    checkBox = projectTypeList.map((data, index) => {
      return (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name={"status"}
                value={data.status}
                onClick={(e) => onChangeChangeProjectType(e)}
                id={index}
                checked={data.ischecked}
              />
            }
            label={data.status}
          />
        </Grid>
      );
    });
    return checkBox;
  } else {
    return [];
  }
};

const ZoneCheckBox = (props) => {
  const [zoneList, setZoneList] = useRecoilState(zoneFilter);
  var checkBox = [];
  if (zoneList.length > 0) {
    const onChangeChangeProjectType = (e) => {
      let value = JSON.parse(JSON.stringify(zoneList));
      value[e.target.id]["ischecked"] = value[e.target.id]["ischecked"]
        ? false
        : true;
      setZoneList(value);
    };

    checkBox = zoneList.map((data, index) => {
      return (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name={"zone"}
                value={data.status}
                onClick={(e) => onChangeChangeProjectType(e)}
                id={index}
                checked={data.ischecked}
              />
            }
            label={
              data.zone === 1
                ? "East"
                : data.zone === 2
                  ? "West"
                  : data.zone === 3
                    ? "North"
                    : data.zone === 4
                      ? "South"
                      : ""
            }
          />
        </Grid>
      );
    });
    return checkBox;
  } else {
    return [];
  }
};
const GetTextBox = (props) => {
  const { min, max, name } = props;
  const [minErr, setMinErr] = useState(false);
  const [maxErr, setMaxErr] = useState(false);
  const [searchValue, setSearchValue] = useRecoilState(searchList);

  const onhandleTextboxValue = (e) => {
    const value = e.target.value.trim();
    if (value === "") {
      setMinErr(false);
      setMaxErr(false);
    } else {
      const numericValue = parseFloat(value);
      if (e.target.name === "min") {
        setMinErr(numericValue < min || numericValue > max);
      } else {
        setMaxErr(numericValue < min || numericValue > max);
      }
    }
    setTextBoxValue(e.target);
  };

  const setTextBoxValue = (value) => {
    let data = JSON.parse(JSON.stringify(searchValue));
    if (data[value.id]) {
      data[value.id][value.name] = value.value;
      setSearchValue(data);
    } else {
      let obj = { [value.id]: { [value.name]: value.value } };
      let merged = { ...data, ...obj };
      setSearchValue(merged);
    }
  };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <TextField
          id={name}
          label={"Min (" + min + ")"}
          variant="outlined"
          name={"min"}
          onChange={onhandleTextboxValue}
          error={minErr}
          value={props.minVal}
        />
        <TextField
          id={name}
          label={"Max (" + max + ")"}
          variant="outlined"
          name={"max"}
          onChange={onhandleTextboxValue}
          error={maxErr}
          value={props.maxVal}
        />
      </div>
      {minErr ? (
        <Typography variant="subtitle1" color={"red"}>
          cannot exceed minimum value
        </Typography>
      ) : null}
      {maxErr ? (
        <Typography variant="subtitle1" color={"red"}>
          cannot exceed maximun value
        </Typography>
      ) : null}
    </div>
  );
};

const FromtoDatePicker = () => {
  const [fromtrialDate, setFromTrialDate] = useRecoilState(filterFromDate);
  const [totrialDate, setToTrialDate] = useRecoilState(filterToDate);

  const handleFromDateChange = (newValue) => {
    const formattedDate = moment(newValue.$d).format("YYYY-MM-DD");
    setFromTrialDate(formattedDate);
  };

  const handleToDateChange = (newValue) => {
    const formattedDate = moment(newValue.$d).format("YYYY-MM-DD");
    setToTrialDate(formattedDate);
  };

  return (
    <Grid container>
      <Grid item xl={6}>
        <FormControl size="small" style={{ width: "100%" }}>
          <TrialDate
            label="From "
            value={fromtrialDate}
            isDate={true}
            onChange={handleFromDateChange}
            maxLimit={new Date()}
            disabled={false}
          />
        </FormControl>
      </Grid>
      <Grid item xl={6}>
        <FormControl size="small" style={{ width: "100%" }}>
          <TrialDate
            label="To"
            value={totrialDate}
            isDate={true}
            onChange={handleToDateChange}
            maxLimit={new Date()}
            disabled={false}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

const SearchTable = (props) => {
  const [, setOpen] = useState(false);
  const [popopen, setPopOpen] = useState(false);
  const [options, setOption] = useRecoilState(checkboxList);
  const [searchData, setSearchValue] = useRecoilState(searchList);
  const [frDate, setFrDate] = useRecoilState(filterFromDate);
  const [toDate, setToDate] = useRecoilState(filterToDate);

  const [, setVisitResData] = useRecoilState(searchValue);
  const [, setFilterData] = useRecoilState(filterDataArray);
  const [, setFilterallExcelData] = useRecoilState(filterExcelData);
  const [totalcount, setTotalCount] = useRecoilState(resetTotlaTableCount);
  const [, setFilter] = useRecoilState(RangeFilterData);
  const [rangeFilter] = useRecoilState(rangeFilterValue);
  const [textboxFilter, setTextBoxFilter] = useState([]);
  const [checkboxFilter, setCheckboxFilter] = useState({});
  const matches = useMediaQuery("(max-width:600px)");
  const [timePickerValue1, setTimePickerValue1] =
    useRecoilState(frmRetentionFilter);
  const [timePickerValue2, setTimePickerValue2] =
    useRecoilState(toRetentionFilter);
  const [, setFilterCategory] = useState({});
  const [userList, setUserList] = useState([]);
  const [projectLocation, setProjectLocation] =
    useRecoilState(projectLocationList);
  const [, setFilterValueCount] = useRecoilState(checkBoxCount);
  const [, setLocation] = useRecoilState(LocationCount);
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsTableLoading] = useRecoilState(AdvanceSearchLoading);
  const [, setFilterTableLoading] = useRecoilState(filterTableLoader);
  const [, setZone] = useState([]);
  const [, setZoneList] = useState({});
  const [, setSeriesData] = useRecoilState(graphSeriesData);
  const getmonthList = configParam.GET_MONTH_LIST;
  const [pageSize, setPageSize] = useState(1);
  const [limit] = useState(10);
  const [total, setTotal] = useRecoilState(advancefilterTotal);
  const ApiURL = configParam.API_URL;
  const [projectTypeList, setProjectTypeList] = useRecoilState(projectType);
  const [typeofConcreteList, setTypeofConcrete] =
    useRecoilState(typeofConcrete);
  const [customer, setCustomerList] = useRecoilState(customerList);
  const [projectDetailLisData, setProjectDetailList] =
    useRecoilState(projectDetailList);
  const [congreteGradeData, setCongreteGrade] = useRecoilState(congreteGrade);
  const [enquiryNumData, setEnquiryNumData] = useRecoilState(enquiryNumberList);
  const [cementBrandData, setCementBrand] = useRecoilState(cementBrand);
  const [selectProjectType, setSelectProjectType] = useState(false);
  const [statusList, setStatus] = useRecoilState(status);
  const [region, setRegion] = useRecoilState(zoneFilter);
  const [techPersionName, setTechPersionName] = useRecoilState(techPerson);
  const [filter, setFilters] = useRecoilState(isFilter);
  const [count, setCount] = useRecoilState(totlaTableCount);
  const [excellRowCount, setExcellRowCount] =
    useRecoilState(excelTotlaTableCount);
  const userDet = localStorage.getItem("user");
  let roleID = JSON.parse(userDet);
  const fromDate = useRecoilValue(filterFromDate);
  const [, setResetFromDate] = useRecoilState(filterFromDate);
  // const toDate = useRecoilValue(filterToDate);
  const [, setResetToDate] = useRecoilState(filterToDate);
  const [, setFilterRowtotalcount] = useRecoilState(AdvanceFilterCount);
  const filteredPage = useRecoilValue(filterPagination);
  const filterRestValue = useRecoilValue(filterInputSearchList)

  useEffect(() => {
    if (props.searchValue) {
      onHandleFilter();
    }

  }, []);

  useEffect(() => {
    setFilterCategory(searchCategory);
    if (props.onHandleFilterSlice) {
      onHandleFilter();
      props.onHandleClose();
    }
  }, [filteredPage.page, filteredPage.pageSize]);

  const handleClose = () => {
    setOpen(false);
    setPopOpen(false);
    props.onHandleClose();
  };
  const checkSearchValue = () => {
    if (props.searchValue && props.searchValue !== null) {
      return props.searchValue;
    } else {
      return "";
    }
  };
  const onHandleFilter = (value) => {
    try {
      setFilterTableLoading(true);
      let retentionTime = timePickerValue1;

      let retentionHr = retentionTime["$H"] * 60;
      let retentionMin = retentionTime["$m"];
      let retention =
        typeof retentionTime === "object"
          ? parseInt(retentionHr) + parseInt(retentionMin)
          : retentionTime;
      let retentionTime1 = timePickerValue2;
      let retention1Hr = retentionTime1["$H"] * 60;
      let retention1Min = retentionTime1["$m"];
      let retention1 =
        typeof retentionTime1 === "object"
          ? parseInt(retention1Hr) + parseInt(retention1Min)
          : retentionTime1;

      let projectTypeId = projectTypeList
        .filter((data) => data.ischecked === true)
        .map((val) => {
          return val.id;
        });

      let typeofConcreteId = typeofConcreteList
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let customerId = customer
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let projectDetailLisId = projectDetailLisData
        .filter((data) => data.ischecked === true)
        .map((val) => val.project_details);
      let congreteGradeId = congreteGradeData
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let cementBrandID = cementBrandData
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let status = statusList
        .filter((data) => data.ischecked === true)
        .map((val) => val.status);
      let tech = techPersionName
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let selectedZone = region
        .filter((data) => data.ischecked === true)
        .map((val) => val.zone);
      let enquiryNum = enquiryNumData.filter((data) => data.ischecked === true)
        .map((val) => val.enquiry_number);

      let url = ApiURL + "advancefilter";
      axios
        .post(url, {
          page: filteredPage.page,
          limit: filteredPage.pageSize,
          searchData: searchData,
          projectTypeId: projectTypeId,
          typeofConcreteId: typeofConcreteId,
          customerId: customerId,
          projectDetailLisId: projectDetailLisId,
          enquiryNum:enquiryNum,
          congreteGradeId: congreteGradeId,
          cementBrandID: cementBrandID,
          tech: tech,
          zone: selectedZone,
          status: status,
          retentionValue: retention,
          retention1Value: retention1,
          fromDate: frDate,
          toDate: toDate,
          // searchValue: props.searchValue ==="empty" ? "":props.searchValue
          searchValue: props.searchValue,
          // searchValue: checkSearchValue()
        })
        .then((res) => {
          if (res.data.data.length > 0) {
            setFilterRowtotalcount(3);
            setFilterData(res.data.data);
            let counts = res.data.count[0].count;
            console.log("counts", counts);
            if (props.searchValue) {
              console.log("if");
              setCount(counts);
            } else {
              setCount(counts);
            }
            handleClose();
            fullFilterDataDownloadExcel();
          } else {
            setFilterRowtotalcount(2);
            setCount(0);
            setVisitResData([]);
            setFilterData([]);
          }
          handleClose();
          setFilterTableLoading(false);
        })
        .catch((error) => {
          console.error("Error in onHandleFilter:", error);
          handleClose();
          setFilterTableLoading(false);
        });
    } catch (error) {
      console.error("Error in onHandleFilter:", error);
      setFilterTableLoading(false); // Hide the loader
    }
  };

  const fullFilterDataDownloadExcel = () => {
    try {
      let retentionTime = timePickerValue1;
      let retentionHr = retentionTime["$H"] * 60;
      let retentionMin = retentionTime["$m"];
      let retention =
        typeof retentionTime === "object"
          ? parseInt(retentionHr) + parseInt(retentionMin)
          : retentionTime;
      let retentionTime1 = timePickerValue2;
      let retention1Hr = retentionTime1["$H"] * 60;
      let retention1Min = retentionTime1["$m"];
      let retention1 =
        typeof retentionTime1 === "object"
          ? parseInt(retention1Hr) + parseInt(retention1Min)
          : retentionTime1;
      setFilters(true);
      setIsTableLoading(true);
      let projectTypeId = projectTypeList
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let typeofConcreteId = typeofConcreteList
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let customerId = customer
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let projectDetailLisId = projectDetailLisData
        .filter((data) => data.ischecked === true)
        .map((val) => val.project_details);
      let congreteGradeId = congreteGradeData
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let cementBrandID = cementBrandData
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let status = statusList
        .filter((data) => data.ischecked === true)
        .map((val) => val.status);
      let tech = techPersionName
        .filter((data) => data.ischecked === true)
        .map((val) => val.id);
      let selectedZone = region
        .filter((data) => data.ischecked === true)
        .map((val) => val.zone);
        let enquiryNum = enquiryNumData.filter((data) => data.ischecked === true)
        .map((val) => val.enquiry_number);
      let url = ApiURL + "advancefilterExcelDownload";
      axios
        .post(url, {
          searchData: searchData,
          projectTypeId: projectTypeId,
          typeofConcreteId: typeofConcreteId,
          customerId: customerId,
          projectDetailLisId: projectDetailLisId,
          congreteGradeId: congreteGradeId,
          cementBrandID: cementBrandID,
          enquiryNum:enquiryNum,
          tech: tech,
          zone: selectedZone,
          status: status,
          retentionValue: retention,
          retention1Value: retention1,
          fromDate: frDate,
          toDate: toDate,
        })
        .then((res) => {
          if (res.data.data.length > 0) {
            console.log("res.data.data.downloadExcel", res.data.data);
            //   setFilterRowtotalcount(true)
            setFilterallExcelData(res.data.data);
            let counts = res.data.count[0].count;
            handleClose();
            setExcellRowCount(counts);
          } else {
            setFilterallExcelData([]);
          }
        });
      setIsTableLoading(false);
    } catch (error) {
      console.error("Error in fullFilterDataDownloadExcel:", error);
      // Handle the error, show a message, or log it as needed
      setIsTableLoading(false);
    }
  };

  const onHandleReset = () => {
    setFilters(false);
    setSearchValue([]);
    setIsLoading(true);
    let url = ApiURL + "DasbordTrialList";
    axios
      .post(url, {
        page: 0,
        limit: 10,
        // ZoneId: roleID.zone_id
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          setFilterRowtotalcount(1);
          setVisitResData(res.data.data);
          setTotalCount(res.data.count);
          setIsLoading(false);
        } else {
          setVisitResData([]);
          setFilterData([]);
          setFilterallExcelData([]);
          setIsLoading(false);
        }
      });
    handleClose();
    onDeSelectAll("zone");
    onDeSelectAll("techPerson");
    onDeSelectAll("project_type");
    onDeSelectAll("type_of_concrete");
    onDeSelectAll("project_details");
    onDeSelectAll("Grade_of_concrete");
    onDeSelectAll("Cement_Brand");
    onDeSelectAll("success_fail");
    onDeSelectAll("customer");
    onDeSelectAll("enquiry_number");
    setTimePickerValue1("");
    setTimePickerValue2("");
    setResetFromDate("");
    setResetToDate("");
    // setFilterallExcelData([]);
  };

  const onHandleRetention = (name, val) => {
    if (!textboxFilter[name]) {
      textboxFilter[name] = val;
    } else {
      textboxFilter[name] = val;
    }
    setTextBoxFilter(textboxFilter);
  };

  const getSplitTextBox = (value) => {
    return (
      <div style={{ display: "flex" }}>
        <div>
          <span>From</span>
          <HourMinInput
            name="fromRetention"
            value={timePickerValue1}
            onChange={(newValue) => {
              setTimePickerValue1(newValue);
              onHandleRetention("fromRetention", newValue);
            }}
          />
        </div>
        <div style={{ paddingLeft: "10px" }}>
          <span style={{ marginLeft: 10 }}>To</span>
          <HourMinInput
            name="toRetention"
            value={timePickerValue2}
            onChange={(newValue) => {
              setTimePickerValue2(newValue);
              onHandleRetention("toRetention", newValue);
            }}
          />
        </div>
      </div>
    );
  };
  const onDeSelectAll = (eve) => {
    const data = checkData(eve);
    let val = JSON.parse(JSON.stringify(data));
    let filterValue;

    for (let i = 0; i < val.length; i++) {
      val[i]["ischecked"] = false;
    }
    filterValue = val;

    SetStateValue(filterValue, eve);

  };

  const onSelectAll = (eve) => {
    const data = checkData(eve.target.name);
    let val = JSON.parse(JSON.stringify(data));
    let filterValue;
    if (eve.target.checked) {
      for (let i = 0; i < val.length; i++) {
        val[i]["ischecked"] = true;
      }

      filterValue = val;
    } else {
      for (let i = 0; i < val.length; i++) {
        val[i]["ischecked"] = false;
      }
      filterValue = val;
    }
    SetStateValue(filterValue, eve.target.name);
  };
  const SetStateValue = (value, name) => {
    switch (name) {
      case "project_type":
        setProjectTypeList(value);
        return true;
      case "type_of_concrete":
        setTypeofConcrete(value);
        return true;
      case "customer":
        setCustomerList(value);
        return true;
      case "project_details":
        setProjectDetailList(value);
        return true;
      case "Grade_of_concrete":
        setCongreteGrade(value);
        return true;
      case "Cement_Brand":
        setCementBrand(value);
        return true;
      case "success_fail":
        setStatus(value);
        return true;
      case "techPerson":
        setTechPersionName(value);
        return true;
      case "enquiry_number":
        setEnquiryNumData(value);
        return true;
      case "zone":
        setRegion(value);
        return true;
    }
  };
  const checkData = (val) => {
    switch (val) {
      case "project_type":
        return projectTypeList;
      case "type_of_concrete":
        return typeofConcreteList;
      case "customer":
        return customer;
      case "project_details":
        return projectDetailLisData;
      case "Grade_of_concrete":
        return congreteGradeData;
      case "Cement_Brand":
        return cementBrandData;
      case "success_fail":
        return statusList;
      case "techPerson":
        return techPersionName;
      case "enquiry_number":
        return enquiryNumData;
      case "zone":
        return region;
    }
  };

  return (
    !props.onHandleFilterSlice && (
      <div>
        <Dialog
          open={popopen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please Fill Mandatory Fields..
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Ok</Button>
          </DialogActions>
        </Dialog>
        <div style={{ width: "100%" }}>
          <Grid container justifyContent={"space-between"}>
            <Typography
              variant="subtitle1"
              style={{
                fontSize: "18px",
                lineHeight: 1.75,
                fontWeight: 600,
                marginBottom: "0",
                padding: "10px",
              }}
            >
              Advanced Filter
            </Typography>
            <Button variant="text" size="small" onClick={handleClose}>
              <CloseIcon style={{ fill: "#000" }} />
            </Button>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ padding: "10px" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Region</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"zone"}
                          value={"all"}
                          onClick={onSelectAll}
                        // checked={selectProjectType}
                        />
                      }
                      label={"Select All"}
                    />
                    <ZoneCheckBox />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Client Details & Parameters</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Technical Person Name</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"techPerson"}
                              value={"all"}
                              onClick={onSelectAll}
                            // checked={selectProjectType}
                            />
                          }
                          label={"Select All"}
                        />
                        <TechPersionCheckBox />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Type of Project</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"project_type"}
                              value={"all"}
                              onClick={onSelectAll}
                            // checked={selectProjectType}
                            />
                          }
                          label={"Select All"}
                        />
                        <ProjectTypeCheckBox />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Enquiry Number</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"enquiry_number"}
                              value={"all"}
                              onClick={onSelectAll}
                            />
                          }
                          label={"Select All"}
                        />
                        <EnquiryNumCheckBox />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Type of Concrete</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"type_of_concrete"}
                              value={"all"}
                              onClick={onSelectAll}
                            />
                          }
                          label={"Select All"}
                        />
                        <TypeofConcreteCheckbox />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Customer Name</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"customer"}
                              value={"all"}
                              onClick={onSelectAll}
                            />
                          }
                          label={"Select All"}
                        />
                        <CustomerListCheckBox />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Project Details</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"project_details"}
                              value={"all"}
                              onClick={onSelectAll}
                            // checked={selectProjectType}
                            />
                          }
                          label={"Select All"}
                        />
                        <ProjectDetailsCheckBox />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Grade of concrete</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"Grade_of_concrete"}
                              value={"all"}
                              onClick={onSelectAll}
                            // checked={selectProjectType}
                            />
                          }
                          label={"Select All"}
                        />
                        <CongreteGradeCheckBox />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Retention (Hr : Min)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>{getSplitTextBox("")}</AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Workability Flow / Slump (mm)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0}
                          max={800}
                          name={"Workability_Flow"}
                          minVal={
                            searchData["Workability_Flow"]
                              ? searchData["Workability_Flow"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["Workability_Flow"]
                              ? searchData["Workability_Flow"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Air Content %</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={1}
                          max={10}
                          name={"air_content"}
                          minVal={
                            searchData["air_content"]
                              ? searchData["air_content"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["air_content"]
                              ? searchData["air_content"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Early Strength (Mpa)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={10}
                          max={60}
                          name={"early_strength_mpa"}
                          minVal={
                            searchData["early_strength_mpa"]
                              ? searchData["early_strength_mpa"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["early_strength_mpa"]
                              ? searchData["early_strength_mpa"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Target Strength 28 days (Mpa)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={10}
                          max={200}
                          name={"target_strength_28_days"}
                          minVal={
                            searchData["target_strength_28_days"]
                              ? searchData["target_strength_28_days"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["target_strength_28_days"]
                              ? searchData["target_strength_28_days"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Date Filter</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FromtoDatePicker />
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Concrete Mix Design Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Cement Brand/Grade/Source</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"Cement_Brand"}
                              value={"all"}
                              onClick={onSelectAll}
                            // checked={selectProjectType}
                            />
                          }
                          label={"Select All"}
                        />
                        <CementBrandCheckBox />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Cement Content ( Kg)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0}
                          max={700}
                          name={"cement_content"}
                          minVal={
                            searchData["cement_content"]
                              ? searchData["cement_content"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["cement_content"]
                              ? searchData["cement_content"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Flyash Content (Kg)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0}
                          max={400}
                          name={"flyash_content"}
                          minVal={
                            searchData["flyash_content"]
                              ? searchData["flyash_content"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["flyash_content"]
                              ? searchData["flyash_content"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>GGBFS Content (Kg)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0}
                          max={400}
                          name={"ggbfs_content"}
                          minVal={
                            searchData["ggbfs_content"]
                              ? searchData["ggbfs_content"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["ggbfs_content"]
                              ? searchData["ggbfs_content"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Microsilica Content (Kg)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0}
                          max={100}
                          name={"microsilica_content"}
                          minVal={
                            searchData["microsilica_content"]
                              ? searchData["microsilica_content"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["microsilica_content"]
                              ? searchData["microsilica_content"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Supporting Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>W/CM (%)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0.1}
                          max={0.6}
                          name={"wmc"}
                          minVal={
                            searchData["wmc"] ? searchData["wmc"]["min"] : ""
                          }
                          maxVal={
                            searchData["wmc"] ? searchData["wmc"]["max"] : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Alkali content in cement(%)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={1}
                          max={5}
                          name={"alkali_content"}
                          minVal={
                            searchData["alkali_content"]
                              ? searchData["alkali_content"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["alkali_content"]
                              ? searchData["alkali_content"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Sand Passing % in 600 µ</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={20}
                          max={100}
                          name={"Sand_Passing_in_650"}
                          minVal={
                            searchData["Sand_Passing_in_650"]
                              ? searchData["Sand_Passing_in_650"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["Sand_Passing_in_650"]
                              ? searchData["Sand_Passing_in_650"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Sand Passing % in 150 µ</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0}
                          max={100}
                          name={"Sand_Passing_in_150"}
                          minVal={
                            searchData["Sand_Passing_in_150"]
                              ? searchData["Sand_Passing_in_150"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["Sand_Passing_in_150"]
                              ? searchData["Sand_Passing_in_150"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Clear Test 10</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0}
                          max={30}
                          name={"clear_test_10"}
                          minVal={
                            searchData["clear_test_10"]
                              ? searchData["clear_test_10"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["clear_test_10"]
                              ? searchData["clear_test_10"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Clear Test 50</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0}
                          max={30}
                          name={"clear_test_50"}
                          minVal={
                            searchData["clear_test_50"]
                              ? searchData["clear_test_50"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["clear_test_50"]
                              ? searchData["clear_test_50"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>
                          Coarse Aggregate 150 micron passing 20 mm
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0}
                          max={20}
                          name={"20mm"}
                          minVal={
                            searchData["20mm"] ? searchData["20mm"]["min"] : ""
                          }
                          maxVal={
                            searchData["20mm"] ? searchData["20mm"]["max"] : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>
                          Coarse Aggregate 150 micron passing 10 mm
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={0}
                          max={20}
                          name={"10mm"}
                          minVal={
                            searchData["10mm"] ? searchData["10mm"]["min"] : ""
                          }
                          maxVal={
                            searchData["10mm"] ? searchData["10mm"]["max"] : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Ambient Temperature (°C)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={-10}
                          max={60}
                          name={"ambient_temperature"}
                          minVal={
                            searchData["ambient_temperature"]
                              ? searchData["ambient_temperature"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["ambient_temperature"]
                              ? searchData["ambient_temperature"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Ambient Humidity (%)</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GetTextBox
                          min={20}
                          max={100}
                          name={"ambient_humidity"}
                          minVal={
                            searchData["ambient_humidity"]
                              ? searchData["ambient_humidity"]["min"]
                              : ""
                          }
                          maxVal={
                            searchData["ambient_humidity"]
                              ? searchData["ambient_humidity"]["max"]
                              : ""
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Success / Fail</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"success_fail"}
                              value={"all"}
                              onClick={onSelectAll}
                            // checked={selectProjectType}
                            />
                          }
                          label={"Select All"}
                        />
                        <StatusCheckBox />
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} style={{ padding: "10px" }}>
              <Button
                variant="contained"
                onClick={onHandleFilter}
                style={{ width: "100%", fontSize: matches ? 10 : null }}
              >
                Apply
              </Button>
            </Grid>

            <Grid item xs={6} style={{ padding: "10px" }}>
              <Button
                variant="outlined"
                style={{ width: "100%", fontSize: matches ? 10 : null }}
                onClick={onHandleReset}
              >
                Clear All
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  );
};
export default SearchTable;
